import React, { FC } from "react";
import { Header } from "../header/header";
import { Services } from "../services/services";
import { Footer } from "../footer/footer";
import { Capabilities } from "../capabilities/capabilities";
import styled from "styled-components";
import { usePageData } from "../../hooks/usePageData";
import { AddressBlock } from "../address";

const StyledLayout = styled.div`
  font-family: "Ubuntu";
  position: relative;

  .layout__content {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 96px;
    font-family: "Ubuntu", sans-serif;

    @media (max-width: 720px) {
      margin-top: 56px;
    }
  }

  .layout__address-block {
    width: 100%;
    height: 638px;
    padding: 132px;

    background-size: cover;
    background-position: center;

    @media (max-width: 767.98px) {
      height: 520px;
      padding: 54px 40px;
    }

    @media (max-width: 767.98px) {
      margin-top: 8px;
      height: 520px;
      padding: 32px;
      background-color: #f2f2f2;
    }
  }

  .layout__address-block-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;

    color: #000000;
  }

  .layout__address-block-description {
    max-width: 420px;
    margin-top: 16px;

    font-size: 16px;
    line-height: 24px;

    color: #5b5b5b;
  }

  .layout__address-block-address {
    margin-top: 8px;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
`;

const Layout: FC = ({ children }) => {
  const { services, capabilities } = usePageData();
  return (
    <StyledLayout>
      <Header />
      <div className="layout__content">
        {children}
        <AddressBlock />
        <Services services={services} />
        <Capabilities capabilities={capabilities} />
      </div>
      <Footer />
    </StyledLayout>
  );
};

export { Layout };
