import styled from "styled-components";

export const Content = styled.div`
  max-width: 1504px;
  padding: 0 32px;
  margin: auto;

  @media (max-width: 720px) {
    padding: 0 16px;
  }
`;
