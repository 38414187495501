import React, { FC } from "react";
import styled from "styled-components";
import { Arrow } from "../../icons";
import { NextIcon } from "../../icons/NextIcon";

const StyledService = styled.div`
  position: relative;
  overflow: hidden;
  width: 50%;
  height: 496px;
  padding: 56px;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 40px;
  }

  @media (max-width: 720px) {
    margin-top: 8px;
    padding: 32px;
    width: 100%;
    height: 456px;

    background-color: #f2f2f2;
  }

  .service__service-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: transform 0.5s;
  }

  .service__service-background:hover {
    transform: scale(1.2);
  }

  .service__content {
    position: relative;
  }

  .service__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;

    color: #000000;
  }

  .service__description {
    max-width: 448px;
    font-size: 16px;
    line-height: 24px;

    color: #5b5b5b;
  }

  .service__icon {
    width: 32px;
    height: 32px;
    margin-top: 16px;

    cursor: pointer;

    @media (max-width: 720px) {
      display: none;
    }
  }

  .service__read-more {
    margin-top: 20px;

    color: #0057ff;

    @media (min-width: 721px) {
      display: none;
    }
  }

  .service__read-more-icon {
    margin-left: 8px;
  }
`;

interface Service {
  serviceTitle: {
    text: string;
  };
  serviceDescription: {
    text: string;
  };
  serviceBgImage: {
    url: string;
    alt?: string;
  };
  onClick?: () => void;
}

const ServiceBlock: FC<Service> = ({
  serviceTitle,
  serviceDescription,
  serviceBgImage,
  onClick
}) => {
  return (
    <StyledService onClick={onClick ? onClick : undefined}>
      <img
        className="lazyload service__service-background"
        data-src={serviceBgImage.url}
      />

      <div className="service__content">
        <h3 className="service__title">{serviceTitle.text}</h3>
        <p className="service__description">{serviceDescription.text}</p>
        <NextIcon className="service__icon" />
        <div className="service__read-more">
          Подробнее
          <Arrow className="service__read-more-icon" />
        </div>
      </div>
    </StyledService>
  );
};

export { ServiceBlock };
