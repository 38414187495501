import React, { useState, useRef } from "react";
import { ContactsHeader } from "./components/contacts";
import { Link } from "gatsby";
import { Menu, Phone, Close } from "../icons";
import styled from "styled-components";
import { Content } from "../content";
import { usePageMessages } from "../../hooks/usePageData";
import { MenuNav } from "./components/menu";
import useOutsideClick from "../../hooks/usOutsideClick";

const StyledHeader = styled.header`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;

  width: 100%;

  font-family: "Ubuntu", sans-serif;
  color: #212121;
  background: #ffffff;
  font-size: 14px;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 5px;
    z-index: 100;
    bottom: 0;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
  }

  .header__header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 100%;
    height: 60px;
  }

  .header__logo {
    display: flex;
    align-items: center;

    cursor: pointer;
  }

  .header__logo-text-icon {
    height: 24px;
    margin-left: 12px;
  }

  .header__button {
    background: transparent;
    border: none;
    cursor: pointer;

    @media (min-width: 768px) {
      display: none;
      cursor: pointer;
    }
  }

  .header__menu-icon {
    width: 24px;
    height: 24px;
    fill: #000;
  }

  .header__icon {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 790px) {
    .header__logo-icon {
      display: none;
    }

    .header__logo-text-icon {
      margin-left: 0;
    }
  }

  @media (max-width: 767.98px) {
    .header__header {
      height: 56px;
    }

    .header__logo-icon {
      display: block;
    }

    .header__logo-text-icon {
      display: none;
    }
  }
`;

const Header = () => {
  const {
    header: { logo, logoMobile }
  } = usePageMessages();

  const [menuOpen, setMenuOpen] = useState(false);
  const [contactsOpen, setContactsOpen] = useState(false);
  const handleCloseMenu = () => setMenuOpen(false);
  const handleCloseContacts = () => setContactsOpen(false);

  const headerRef = useRef(null);

  useOutsideClick(headerRef, () => {
    handleCloseMenu();
    setContactsOpen(false);
  });

  return (
    <StyledHeader ref={headerRef}>
      <ContactsHeader open={contactsOpen} />
      <Content>
        <div className="header__header-wrap">
          <button className="header__button">
            {!menuOpen && (
              <Menu
                className="header__menu-icon"
                onClick={() => {
                  setMenuOpen(!menuOpen);
                  handleCloseContacts();
                }}
              />
            )}
            {menuOpen && (
              <Close
                className="header__icon"
                onClick={() => {
                  handleCloseMenu();
                  handleCloseContacts();
                }}
              />
            )}
          </button>

          <Link to="/" className="header__logo">
            <img className="header__logo-icon" src={logoMobile} />
            <img className="header__logo-text-icon" src={logo} />
          </Link>

          <button className="header__button">
            {!contactsOpen && (
              <Phone
                className="header__icon"
                onClick={() => {
                  handleCloseMenu();
                  setContactsOpen(true);
                }}
              />
            )}
            {contactsOpen && (
              <Close
                className="header__icon"
                onClick={() => {
                  handleCloseMenu();
                  handleCloseContacts();
                }}
              />
            )}
          </button>

          <MenuNav open={menuOpen} onClose={handleCloseMenu} />
        </div>
      </Content>
    </StyledHeader>
  );
};

export { Header };
