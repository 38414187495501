import { Link } from "gatsby";
import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { usePageMessages } from "../../hooks/usePageData";
import { LogoTextIcon, YellowSquare } from "../icons";
import { RequestCall } from "../RequestCall";

const StyledFooter = styled.footer`
  width: 100%;
  margin-top: 8px;

  .footer {
    color: #ffffff;
    background: #111111;

    font-family: "Ubuntu", sans-serif;
  }

  .footer__container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 64px 120px 64px 112px;

    @media (max-width: 1210px) {
      padding: 64px 40px;
    }

    @media (max-width: 996px) {
      padding: 40px;
    }

    @media (max-width: 720px) {
      padding: 32px 16px;
    }
  }

  .footer__content {
    display: flex;
    justify-content: space-between;

    @media (max-width: 720px) {
      flex-direction: column;
    }
  }

  .footer__title {
    opacity: 0.32;

    font-size: 18px;
    line-height: 24px;
  }

  .footer__description {
    margin-top: 4px;

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    a {
      display: block;
    }
  }

  .footer__column {
    display: flex;

    @media (max-width: 720px) {
      flex-direction: column;
    }
  }

  .footer__col + .footer__col {
    margin-left: 64px;

    @media (max-width: 720px) {
      margin-top: 32px;
      margin-left: 0;
    }
  }

  .footer__location {
    width: 148px;
    height: 148px;

    border-radius: 8px;
    background: #ffffff;

    @media (max-width: 996px) {
      width: 243px;
      height: 148px;
    }

    @media (max-width: 720px) {
      display: none;
    }
  }

  .footer__contacts,
  .footer__address,
  .footer__schedule {
    display: flex;
    flex-direction: column;
    max-width: 182px;
  }

  .footer__socials {
    display: flex;
    margin-top: 28px;
  }

  .footer__social + .footer__social {
    margin-left: 16px;
  }

  .footer__social {
    width: 24px;
    height: 24px;
  }

  .footer__logo {
    width: 95px;
    height: 24px;
  }

  .footer__info {
    display: flex;
    justify-content: space-between;

    margin-top: 108px;

    @media (max-width: 996px) {
      margin-top: 32px;
      padding-top: 28px;
      border-top: 1px solid #5b5b5b;
    }

    @media (max-width: 720px) {
      flex-direction: column;

      margin-top: 40px;
    }
  }

  .footer__dev {
    display: flex;
    align-items: flex-end;

    font-size: 14px;
    line-height: 16px;

    @media (max-width: 720px) {
      margin-top: 16px;
    }
  }

  .footer__dev-text {
    margin-right: 3px;
  }

  .footer__dev-link {
    max-height: 14px;
  }

  .footer__order-call-wrap {
    display: flex;

    @media (min-width: 1050px) {
      display: none;
    }

    @media (max-width: 720px) {
      display: none;
    }

    img {
      width: 50%;
      background-size: cover;
    }
  }

  .footer__order-call-wrap > * {
    flex: 1;
  }

  .footer__request-call-container {
    max-width: 315px;

    @media (max-width: 1050px) and (min-width: 720px) {
      display: none;
    }

    @media (max-width: 720px) {
      max-width: 100%;
    }
  }

  .footer__request-call-title {
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    opacity: 0.32;
  }

  .footer__request-call {
    margin-top: 4px;
  }

  .footer__request-call-section {
    height: 100%;
    padding: 0px 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(
        350.09deg,
        #ffffff -38.78%,
        rgba(255, 255, 255, 0) 162.56%
      ),
      #e5e5e5;
  }

  .footer__request-call-section-container {
    max-width: 315px;
  }

  .footer__request-call-section-title {
    font-size: 18px;
    line-height: 24px;
    color: #000000;
  }
`;

interface Phone {
  phone_number: {
    text: string;
  };
}

const Footer = () => {
  const {
    footer: { email, phoneNumbers, address, imageUrl, socials, schedule }
  } = usePageMessages();

  const [yandexMapUrl, setYandexMapUrl] = useState("");

  useLayoutEffect(() => {
    setYandexMapUrl(
      "https://yandex.ru/map-widget/v1/?um=constructor%3A0560fa8ccdc21e7ffcdee4614285fcfc83c70eaa88a35ab9fa6bf12801ec0a11&amp;source=constructor"
    );
  }, []);

  return (
    <StyledFooter>
      <div className="footer__order-call-wrap">
        <div>
          <div className="footer__request-call-section">
            <div className="footer__request-call-section-container">
              <div className="footer__request-call-section-title">
                Хотите, чтобы мы связались с вами?
              </div>
              <RequestCall className="footer__request-call" />
            </div>
          </div>
        </div>
        <img className="lazyload" data-src={imageUrl}></img>
      </div>
      <div className="footer">
        <div className="footer__container">
          <div className="footer__content">
            <div className="footer__request-call-container">
              <div className="footer__request-call-title">
                Хотите, чтобы мы связались с вами?
              </div>
              <RequestCall className="footer__request-call" contrast />
            </div>
            <div className="footer__column">
              <iframe
                className="footer__col footer__location"
                src={yandexMapUrl}
              ></iframe>
              <div className="footer__col footer__contacts" id="contacts">
                <span className="footer__title">Наши контакты:</span>
                <div className="footer__description">
                  {phoneNumbers.map((phone: string) => (
                    <a href={`tel:${phone}`} key={phone}>
                      {phone}
                    </a>
                  ))}
                </div>
                <span className="footer__description">{email}</span>
                <ul className="footer__socials">
                  {socials &&
                    socials.map(
                      (
                        social: {
                          link: string;
                          icon: { url: string; alt: string };
                        },
                        index: number
                      ) => (
                        <li className="footer__social" key={index}>
                          <a href={social.link} target="_blank">
                            <img
                              className="lazyload"
                              data-src={social.icon.url}
                              alt={social.icon.alt}
                            />
                          </a>
                        </li>
                      )
                    )}
                </ul>
              </div>
              <div className="footer__col">
                <div className="footer__address">
                  <span className="footer__title">{address.title}</span>
                  <span className="footer__description">
                    {address.description}
                  </span>
                </div>
                <div className="footer__schedule">
                  <span className="footer__title">{schedule.title}</span>
                  <span className="footer__description">
                    {schedule.description}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__info">
            <div className="footer__logo">
              <Link to="/">
                <LogoTextIcon fill="#fff" />
              </Link>
            </div>

            <div className="footer__dev">
              <span className="footer__dev-text">Разработано</span>
              <a
                className="footer__dev-link"
                href="https://yellowsquare.dev/"
                target="_blank"
              >
                <YellowSquare />
              </a>
            </div>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

export { Footer };
