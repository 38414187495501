import React, { FC } from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { Slider } from "../../slider";
import { IphoneModelBlock } from "./iphonemodelblock";
import { secondhandIphoneMessages } from "../../../hooks/useSecondhandIphoneData";

const StyledSecondhandIphone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;

  @media (min-width: 600px) {
    max-width: 656px;
    padding: 1em;
  }

  @media (min-width: 769px) {
    max-width: 1000px;
  }

  @media (min-width: 1186px) {
    max-width: 1112px;
    padding: 2em;
  }

  .second-hand-iphone__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;

    @media (min-width: 600px) {
      margin-top: 32px;
    }

    @media (min-width: 769px) {
      font-size: 28px;
    }
  }

  .second-hand-iphone__subtitle {
    margin-top: 4px;
    max-width: 505px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: #5b5b5b;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .gallery {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    @media (max-width: 600px) {
      width: 100%;
      flex-wrap: nowrap;
    }
  }

  .second-hand-iphone__gallery--mobile {
    display: none;

    @media (max-width: 600px) {
      display: flex;
    }
  }

  .gallery__item {
    width: 100%;
    margin-top: 32px;
    padding-bottom: 32px;

    @media (min-width: 599px) {
      margin-top: 58px;
      width: 248px;
      padding-bottom: 0;
    }
    @media (min-width: 769px) {
      width: 296px;
      margin-top: 64px;
    }
  }
`;

const SecondhandIphoneMobile: FC = () => {
  const { title, subtitle, iphones } = secondhandIphoneMessages();

  const width = useWindowSize();

  if (width > 600) {
    navigate("/");
  }

  return (
    <>
      <StyledSecondhandIphone>
        <h3 className="second-hand-iphone__title">{title}</h3>
        <h5 className="second-hand-iphone__subtitle">{subtitle}</h5>
        <div className="second-hand-iphone__gallery--mobile gallery">
          <Slider>
            {iphones.map((model: any, index: number): any => {
              return (
                <div key={index} className="gallery__item">
                  <IphoneModelBlock
                    iphoneModel={model.iphoneModel}
                    iphoneDescription={model.iphoneDescription}
                    iphoneImageUrl={model.iphoneImageUrl}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
      </StyledSecondhandIphone>
    </>
  );
};

export { SecondhandIphoneMobile };
