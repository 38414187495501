import { graphql, useStaticQuery } from "gatsby";

export const usePageData = () => {
  const { prismicPage } = useStaticQuery(
    graphql`
      query {
        prismicPage {
          data {
            address_block_bg_image {
              url
              alt
            }
            address_block_description {
              text
            }
            address_block_title {
              text
            }
            address_block_address {
              text
            }
            capabilities {
              capability_description {
                text
              }
              capability_icon {
                url
              }
              capability_title {
                text
              }
            }
            footer_bg_image {
              url
            }
            footer_address_description {
              text
            }
            footer_address_title {
              text
            }
            footer_contacts_phones {
              phone_number {
                text
              }
            }
            footer_email {
              text
            }
            footer_schedule_description {
              text
            }
            footer_schedule_title {
              text
            }
            header_address {
              text
            }
            header_email {
              text
            }
            header_phone_numbers {
              phone_number {
                text
              }
            }
            header_logo {
              url
            }
            header_mobile_logo {
              url
            }
            header_social_links {
              icon {
                url
                alt
              }
              link {
                url
              }
            }
            services {
              service_bg_image {
                url
              }
              service_description {
                text
              }
              service_title {
                text
              }
            }
            footer_social_links {
              icon {
                url
                alt
              }
              link {
                url
              }
            }
          }
        }
      }
    `
  );
  return prismicPage.data;
};

interface Phone {
  phone_number: {
    text: string;
  };
}

interface Social {
  icon: {
    url: string;
    alt: string;
  };
  link: {
    url: string;
  };
}
interface Capability {
  capability_title: {
    text: string;
  };
  capability_description: {
    text: string;
  };
  capability_icon: {
    url: string;
    alt: string;
  };
}

interface Service {
  service_title: {
    text: string;
  };
  service_description: {
    text: string;
  };
  service_bg_image: {
    url: string;
    alt?: string;
  };
}

const usePageMessages = () => {
  const pageData = usePageData();
  return {
    header: {
      address: pageData.header_address.text,
      email: pageData.header_email.text,
      phoneNumbers: pageData.header_phone_numbers.map(
        (phone: Phone) => phone.phone_number.text
      ),
      logo: pageData.header_logo.url,
      logoMobile: pageData.header_mobile_logo.url,
      socials: pageData.header_social_links.map((social: Social) => ({
        icon: social.icon,
        link: social.link.url
      }))
    },
    addressBlock: {
      imageUrl: pageData.address_block_bg_image.url,
      description: pageData.address_block_description.text,
      title: pageData.address_block_title.text,
      address: pageData.address_block_address.text
    },
    capabilities: pageData.capabilities.map((capability: Capability) => ({
      title: capability.capability_title.text,
      description: capability.capability_description.text,
      iconUrl: capability.capability_icon.url
    })),
    services: pageData.services.map((service: Service) => ({
      title: service.service_title.text,
      description: service.service_description.text,
      imageUrl: service.service_bg_image.url
    })),
    footer: {
      imageUrl: pageData.footer_bg_image.url,
      address: {
        title: pageData.footer_address_title.text,
        description: pageData.footer_address_description.text
      },
      email: pageData.footer_email.text,
      phoneNumbers: pageData.footer_contacts_phones.map(
        (phone_number: Phone) => phone_number.phone_number.text
      ),
      schedule: {
        title: pageData.footer_schedule_title.text,
        description: pageData.footer_schedule_description.text
      },
      socials: pageData.footer_social_links.map((social: Social) => ({
        icon: social.icon,
        link: social.link.url
      }))
    }
  };
};

export { usePageMessages };
