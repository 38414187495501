import { useStaticQuery, graphql } from "gatsby";

const useSecondhandIphoneData = () => {
  const { prismicSecondhandiphone } = useStaticQuery(graphql`
    query {
      prismicSecondhandiphone {
        data {
          body {
            primary {
              iphone_model {
                text
              }
              iphone_description {
                text
              }
              phone_image {
                url
              }
            }
          }
          subtitle {
            text
          }
          title {
            text
          }
        }
      }
    }
  `);
  return prismicSecondhandiphone.data;
};

const secondhandIphoneMessages = () => {
  const secondhandIphoneData = useSecondhandIphoneData();

  return {
    title: secondhandIphoneData.title.text,
    subtitle: secondhandIphoneData.subtitle.text,
    iphones: secondhandIphoneData.body.map((iphone: any) => ({
      iphoneModel: iphone.primary.iphone_model.text,
      iphoneImageUrl: iphone.primary.phone_image.url,
      iphoneDescription: iphone.primary.iphone_description.text
    }))
  };
};

export { secondhandIphoneMessages };
