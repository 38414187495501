import React, { FC } from "react";
import Swiper from "react-id-swiper";
import "swiper/swiper.scss";
import styled from "styled-components";

const StyledSwiper = styled.div`
  display: flex;
  width: 100%;

  .swiper-scrollbar {
    position: relative;
    bottom: 2px;
    display: flex;
    align-items: center;
    height: 1px;
    background-color: #e5e5e5;
  }

  .swiper-scrollbar-drag {
    height: 3px;
    background-color: #5b5b5b;
    border-radius: 8px;
  }
`;

const Slider: FC = ({ children }: any) => {
  const params = {
    speed: 500,
    scrollbar: {
      el: ".swiper-scrollbar",
      hide: false
    },
    spaceBetween: 20,
    autoplay: {
      delay: 4000
    }
  };

  return (
    <StyledSwiper>
      <Swiper {...params}>
        {children.map((elem: any, index: number) => (
          <div key={index} className="swiper-slide">
            {elem}
          </div>
        ))}
      </Swiper>
    </StyledSwiper>
  );
};

export { Slider };
