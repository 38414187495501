import React, { FC, SVGAttributes } from "react";

const Close: FC<SVGAttributes<SVGSVGElement>> = props => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4.36 3.006L3.006 4.36l7.545 7.545-7.59 7.604 1.34 1.34 7.604-7.59 7.59 7.59 1.353-1.355-7.59-7.59 7.546-7.544-1.355-1.354-7.544 7.545L4.36 3.006z" />
    </svg>
  );
};

export { Close };
