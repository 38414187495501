import React from "react";
import styled from "styled-components";

const StyledIphoneModelBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .model-block__image {
    width: 100%;
    border-radius: 4px;
  }

  .model-block__title {
    margin-top: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000;

    @media (min-width: 600px) {
      font-size: 16px;
    }

    @media (min-width: 769px) {
      margin-top: 20px;
    }
  }

  .model-block__desc {
    margin-top: 8px;
    font-size: 14px;
    line-height: 24px;
    color: #5b5b5b;

    @media (max-width: 599px) {
      margin-top: 12px;
    }
  }
`;

interface Props {
  iphoneModel: string;
  iphoneDescription: string;
  iphoneImageUrl: string;
}

const IphoneModelBlock = ({
  iphoneModel,
  iphoneDescription,
  iphoneImageUrl
}: Props) => {
  return (
    <StyledIphoneModelBlock>
      <img
        data-src={iphoneImageUrl}
        alt={iphoneModel}
        className="lazyload model-block__image"
      />
      <h6 className="model-block__title">{iphoneModel}</h6>
      <p className="model-block__desc">{iphoneDescription}</p>
    </StyledIphoneModelBlock>
  );
};

export { IphoneModelBlock };
