import React, { useCallback, useEffect, useState, FC } from "react";
import { Link } from "gatsby";
import { Location, navigate } from "@reach/router";
import styled from "styled-components";
import { ServiceBlock } from "./components/serviceblock";
import { Modal } from "../modal";
import { WarrantyRepairDesktop } from "../warrantyrepair";
import { SecondhandIphoneDesktop } from "../secondhandiphone";

const StyledServices = styled.div`
  display: flex;

  @media (max-width: 720px) {
    order: 1;
    flex-direction: column;
  }

  .homePage {
    flex-direction: column;

    .service {
      width: 440px;
      height: 402px;
    }
  }

  .service--mobile {
    display: none;

    @media (max-width: 599px) {
      display: flex;
      flex-direction: column;
    }
  }

  .service--desktop {
    display: none;
    width: 100%;

    @media (min-width: 600px) {
      display: flex;
      flex-direction: column;
    }

    @media (min-width: 720px) {
      flex-direction: row;
    }
  }
`;

interface Props {
  services: Service[];
  className?: string;
}

interface Service {
  service_title: {
    text: string;
  };
  service_description: {
    text: string;
  };
  service_bg_image: {
    url: string;
    alt?: string;
  };
}

const Services = ({ services, className }: Props) => {
  const [warrantyRepairBlock, secondHandPhonesBlock] = services;
  const [isWarrantyRepairModalOpen, setIsWarrantyRepairModalOpen] = useState(
    false
  );
  const [
    isSecondhandIphoneModalOpen,
    setIsSecondhandIphoneModalOpen
  ] = useState(false);

  const handleOpenWarrantyRepairModal = useCallback(
    () => setIsWarrantyRepairModalOpen(true),
    []
  );

  const handleOpenSecondhandIphoneModal = useCallback(
    () => setIsSecondhandIphoneModalOpen(true),
    []
  );

  const handleCloseModal = useCallback((event: Event) => {
    event.preventDefault();
    setIsWarrantyRepairModalOpen(false);
    setIsSecondhandIphoneModalOpen(false);
  }, []);

  const WarrantyRepairBlock: FC = () => (
    <Link to="/warrantyrepair">
      <ServiceBlock
        serviceTitle={warrantyRepairBlock.service_title}
        serviceDescription={warrantyRepairBlock.service_description}
        serviceBgImage={warrantyRepairBlock.service_bg_image}
      />
    </Link>
  );

  const SecondhandIphoneBlock: FC = () => (
    <Link to="/secondhandiphone">
      <ServiceBlock
        serviceTitle={secondHandPhonesBlock.service_title}
        serviceDescription={secondHandPhonesBlock.service_description}
        serviceBgImage={secondHandPhonesBlock.service_bg_image}
      />
    </Link>
  );

  return (
    <StyledServices className={className}>
      <div className="service--mobile">
        <Location>
          {({ location: { pathname } }) => {
            if (pathname === "/secondhandiphone") {
              return <WarrantyRepairBlock />;
            } else if (pathname === "/warrantyrepair") {
              return <SecondhandIphoneBlock />;
            } else {
              return (
                <div>
                  <SecondhandIphoneBlock />
                  <WarrantyRepairBlock />
                </div>
              );
            }
          }}
        </Location>
      </div>
      <div className="service--desktop">
        <ServiceBlock
          serviceTitle={warrantyRepairBlock.service_title}
          serviceDescription={warrantyRepairBlock.service_description}
          serviceBgImage={warrantyRepairBlock.service_bg_image}
          onClick={handleOpenWarrantyRepairModal}
        />
        <ServiceBlock
          serviceTitle={secondHandPhonesBlock.service_title}
          serviceDescription={secondHandPhonesBlock.service_description}
          serviceBgImage={secondHandPhonesBlock.service_bg_image}
          onClick={handleOpenSecondhandIphoneModal}
        />
      </div>
      <Modal isOpen={isWarrantyRepairModalOpen} onClose={handleCloseModal}>
        <WarrantyRepairDesktop />
      </Modal>
      <Modal isOpen={isSecondhandIphoneModalOpen} onClose={handleCloseModal}>
        <SecondhandIphoneDesktop />
      </Modal>
    </StyledServices>
  );
};

export { Services };
