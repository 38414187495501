import cn from "classnames";
import { format } from "date-fns";
import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import styled from "styled-components";
import { PaperPlane } from "../icons/PaperPlane";
import { Spinner } from "../icons/Spinner";
import InputMask from "react-input-mask";

const StyledRequestCall = styled.form`
  .request-call__title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #5b5b5b;
  }

  .request-call__field {
    position: relative;
    margin-top: 12px;
  }

  .request-call__field-input {
    width: 100%;
    height: 48px;

    appearance: none;

    padding: 4px calc(104px + 4px + 4px) 4px 16px;

    border: 1px solid #cbcbcb;
    border-radius: 8px;

    font-size: 18px;
    font-weight: 500;
    line-height: 16px;

    &::placeholder {
      font-size: 18px;
      font-weight: 500;
      line-height: 16px;
    }
  }

  .request-call__field-input--error {
    border: 1px solid #ff0000;
    background: #fff1f1;
  }

  .request-call__field-input--success {
    border: 1px solid #2c6dec;
  }

  .request-call__field-button {
    position: absolute;
    top: 4px;
    right: 4px;
    height: calc(100% - 8px);

    display: flex;
    justify-content: center;
    align-items: center;

    width: 104px;

    border: none;
    border-radius: 4px;
    background: #2c6dec;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
  }

  .request-call__field-button:disabled {
    cursor: default;
    opacity: 0.72;
  }

  .request-call__spinner {
    width: 24px;
    height: 24px;

    stroke: #ffffff;
  }

  .request-call__field-icon {
    display: none;
  }

  .request-call__hint {
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    opacity: 0.24;
  }

  .request-call__message {
    min-height: 28px;
    margin-top: 4px;
    font-size: 12px;
    line-height: 14px;
  }

  .request-call__error {
    color: #ff0000;
  }

  .request-call__success {
    color: #2c6dec;
  }

  /* Contrast */
  &.request-call--contrast {
    .request-call__title {
      color: #ffffff;
    }

    .request-call__hint {
      color: #ffffff;
      opacity: 0.24;
    }
  }

  @media (max-width: 456px) {
    .request-call__title {
      font-size: 16px;
      line-height: 20px;
    }

    .request-call__field-input {
      padding-right: calc(24px + 8px + 16px);
    }

    .request-call__field-button {
      width: 24px;
      height: 24px;
      background: none;
      top: 50%;
      padding: 0;
      right: 14px;
      -webkit-transform: translateY(-50%);
      span {
        display: none;
      }
    }

    .request-call__field-icon {
      display: block;
      width: 24px;
      height: 24px;
      fill: #0057ff;
      stroke: #0057ff;
    }

    .request-call__spinner {
      stroke: #0057ff;
    }
  }
`;

interface Props {
  className?: string;
  contrast?: boolean;
  onSuccess?: () => void;
}

const RequestCall: FC<Props> = ({ className, contrast, onSuccess }) => {
  const [phone, setPhone] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const phoneRegExp = /^[\s\d+\-()]*$/;
    if (phoneRegExp.test(e.target.value)) {
      setError(false);
      setPhone(e.target.value);
    }
    validate(e.target.value);
  };

  const validate = (phone: string) => {
    if (phone.length === 19) {
      setIsValid(true);
      setError(false);
    } else {
      setIsValid(false);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) {
      setError(true);
      return;
    }

    setError(false);

    setIsSubmitting(true);
    setServerError(false);
    const form = e.target as HTMLFormElement;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name") as string,
        phone,
        date: format(new Date(), "HH:mm dd.MM.yyyy")
      })
    })
      .then(checkStatus)
      .then(() => {
        setIsSubmitting(false);
        onSuccess ? onSuccess() : setSuccess(true);
      })
      .catch(error => {
        setIsSubmitting(false);
        setServerError(true);
      })
      .then(() => {
        setPhone("");
      });
  };

  return (
    <StyledRequestCall
      className={cn(className, { "request-call--contrast": contrast })}
      onSubmit={handleSubmit}
      name="contact"
      data-netlify="true"
    >
      <div className="request-call__title">
        Оставьте свой телефон, и мы вам перезвоним
      </div>

      <div className="request-call__field">
        <InputMask
          className={cn("request-call__field-input", {
            "request-call__field-input--error": error || serverError,
            "request-call__field-input--success": success
          })}
          mask="+375 (99) 999-99-99"
          placeholder="+375 (33) 623-85-67"
          maskChar=""
          value={phone}
          onChange={handleChange}
          name="phone"
          inputMode="tel"
        />
        <input type="hidden" name="date" />
        <button
          className="request-call__field-button"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <Spinner className="request-call__spinner" />
          ) : (
            <>
              <span>Отправить</span>
              <PaperPlane className="request-call__field-icon" />
            </>
          )}
        </button>
      </div>
      <div className="request-call__message">
        {serverError ? (
          <span className="request-call__error">
            Что-то пошло не так. Перезагрузите страницу и отправьте запрос еще
            раз."
          </span>
        ) : error ? (
          <span className="request-call__error">
            Проверьте, пожалуйста, корректность номера телефона
          </span>
        ) : success ? (
          <span className="request-call__success">
            Спасибо, мы перезвоним вам в ближайшее время
          </span>
        ) : (
          ""
        )}
      </div>
      <div className="request-call__hint">
        Нажимая на кнопку отправить, вы соглашаетесь на обработку персональных
        данных.
      </div>
    </StyledRequestCall>
  );
};

function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  throw new Error(response.statusText);
}

const encode = (data: { [key: string]: string }) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export { RequestCall };
