import React, { FC, SVGAttributes } from "react";

const ArrowDown: FC<SVGAttributes<SVGSVGElement>> = props => {
  return (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1 1L7 7L13 1" stroke="black" />
    </svg>
  );
};

export { ArrowDown };
