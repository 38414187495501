import React, { FC, useState } from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { Slider } from "../../slider";
import { Category } from "./Category";
import { useWarrantyRepairMessages } from "../../../hooks/useWarrantyRepair";

const StyledWarrantyRepair = styled.div`
  @media (min-width: 320px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 2em;
  }

  @media (max-width: 600px) {
    max-width: 600px;
    background: linear-gradient(
        318.52deg,
        rgba(255, 255, 255, 0.5) 16.44%,
        rgba(255, 255, 255, 0) 102.9%
      ),
      rgba(223, 223, 223, 0.5);
  }

  @media (min-width: 601px) {
    align-items: center;
    padding: 2em 2em;
  }

  @media (min-width: 769px) {
    max-width: 700px;
    padding: 2em 4em;
  }

  @media (min-width: 1000px) {
    max-width: unset;
  }

  .warranty-repair__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;

    @media (min-width: 769px) {
      font-size: 28px;
    }
  }

  .warranty-repair__subtitle {
    margin-top: 8px;
    font-size: 18px;
    line-height: 24px;
    color: #5b5b5b;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .category-block {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 88px;
    max-width: 930px;

    @media (max-width: 769px) {
      flex-direction: column;
      margin-top: 48px;
      width: 100%;
    }

    @media (max-width: 599px) {
      margin-top: 40px;
    }

    @media (max-width: 600px) {
      flex-direction: row;
    }
  }

  .category-block--mobile {
    display: none;
    width: 100%;

    @media (max-width: 600px) {
      display: flex;
    }
  }

  .warranty-repair__helper-text {
    display: none;

    @media (min-width: 769px) {
      display: block;
      margin-top: 45px;
      max-width: 530px;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
    }
  }
`;

const WarrantyRepairMobile: FC = () => {
  const {
    title,
    subtitle,
    helperText,
    categories
  } = useWarrantyRepairMessages();

  const width = useWindowSize();

  if (width > 600) {
    navigate("/");
  }

  return (
    <>
      <StyledWarrantyRepair>
        <h3 className="warranty-repair__title">{title}</h3>
        <h5 className="warranty-repair__subtitle">{subtitle}</h5>
        <div className="category-block category-block--mobile">
          <Slider>
            {categories.map((elem: any) => (
              <Category
                key={elem.categoryTitle}
                icon={elem.categoryIconUrl}
                categoryTitle={elem.categoryTitle}
                list={elem.categoryList}
              />
            ))}
          </Slider>
        </div>
        <h5 className="warranty-repair__helper-text">{helperText}</h5>
      </StyledWarrantyRepair>
    </>
  );
};

export { WarrantyRepairMobile };
