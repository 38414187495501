import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";
import { RequestCall } from "../../components/RequestCall";
import { Checked } from "../../icons/Checked";

const StyledRequestCallModal = styled.div`
  display: flex;
  overflow: hidden;
  font-family: Ubuntu, "Open Sans", "Helvetica Neue", sans-serif;

  .request-call-modal__content {
    width: 440px;
    padding: 64px;
  }

  .request-call-modal__title {
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;

    color: #000000;
  }

  .request-call-modal__request-call {
    margin-top: 32px;
  }

  .request-call-modal__image {
    height: 100%;
    width: 256px;
  }

  .request-call-modal__success {
    padding: 49px 30px 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .request-call-modal__successIcon {
    width: 64px;
    height: 64px;
    fill: #2c6dec;
  }

  .request-call-modal__successText {
    margin-top: 32px;

    font-size: 20px;
    line-height: 28px;
    text-align: center;

    color: #000000;
  }

  @media (max-width: 712px) {
    .request-call-modal__image {
      display: none;
    }
  }

  @media (max-width: 456px) {
    .request-call-modal__request-call {
      margin-top: 20px;
    }

    .request-call-modal__title {
      font-size: 24px;
      line-height: 28px;
    }

    .request-call-modal__content {
      padding: 42px 20px;
      width: 100%;
    }
  }
`;

interface Props {}

const RequestCallModal: FC<Props> = () => {
  const [isSucceed, setIsSucceed] = useState(false);
  const onSuccess = useCallback(() => setIsSucceed(true), []);

  return (
    <StyledRequestCallModal>
      {!isSucceed ? (
        <>
          <div className="request-call-modal__content">
            <div className="request-call-modal__title">
              Хотите, чтобы мы связались с вами?
            </div>
            <RequestCall
              className="request-call-modal__request-call"
              onSuccess={onSuccess}
            />
          </div>
          <img
            className="request-call-modal__image"
            data-src="/request-call.jpg"
          />
        </>
      ) : (
        <div className="request-call-modal__success">
          <Checked className="request-call-modal__successIcon" />
          <div className="request-call-modal__successText">
            Спасибо, мы свяжемся с вами
            <br />в ближайшее время
          </div>
        </div>
      )}
    </StyledRequestCallModal>
  );
};

export { RequestCallModal };
