import React from "react";
import styled from "styled-components";
import { Slider } from "../slider";

const StyledCapabilities = styled.ul`
  .capabilities {
    display: grid;
    grid-template-columns: repeat(auto-fit, 336px);
    grid-gap: 80px 104px;

    padding: 112px 40px;
    justify-content: center;

    @media (max-width: 1298px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 996px) {
      padding: 64px 40px;
      grid-template-columns: repeat(auto-fit, 320px);
      grid-gap: 56px 48px;
    }

    @media (max-width: 720px) {
      display: none;
    }
  }

  .capabilities--mobile {
    margin-top: 8px;
    padding: 41px 32px 48px;
    @media (min-width: 721px) {
      display: none;
    }
  }

  .capabilities__capability {
    max-width: 336px;

    @media (max-width: 720px) {
      padding-bottom: 16px;
    }
  }

  .capabilities__capability + .capabilities__capability {
    @media (max-width: 720px) {
      display: none;
    }
  }

  .capabilities__icon {
    width: 64px;
    height: 64px;
  }

  .capabilities__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;

    color: #000000;
  }

  .capabilities__description {
    font-size: 16px;
    line-height: 24px;

    color: #959595;
  }
`;
interface Capability {
  capability_title: {
    text: string;
  };
  capability_description: {
    text: string;
  };
  capability_icon: {
    url: string;
    alt: string;
  };
}

interface Props {
  capabilities: Capability[];
}

const Capabilities = ({ capabilities }: Props) => {
  return (
    <StyledCapabilities id="services">
      <ul className="capabilities--mobile">
        <Slider>
          {capabilities.map(
            ({ capability_description, capability_icon, capability_title }) => (
              <li
                key={capability_title.text}
                className="capabilities__capability"
              >
                <img
                  className="lazyload capabilities__icon"
                  data-src={capability_icon.url}
                  alt={capability_icon.alt}
                />
                <h3 className="capabilities__title">{capability_title.text}</h3>
                <span className="capabilities__description">
                  {capability_description.text}
                </span>
              </li>
            )
          )}
        </Slider>
      </ul>
      <ul className="capabilities">
        {capabilities.map(
          ({ capability_description, capability_icon, capability_title }) => (
            <li
              key={capability_title.text}
              className="capabilities__capability"
            >
              <img
                className="lazyload capabilities__icon"
                data-src={capability_icon.url}
                alt={capability_icon.alt}
              />
              <h3 className="capabilities__title">{capability_title.text}</h3>
              <span className="capabilities__description">
                {capability_description.text}
              </span>
            </li>
          )
        )}
      </ul>
    </StyledCapabilities>
  );
};

export { Capabilities };
