export * from "./Arrow";
export * from "./Close";
export * from "./CloseModal";
export * from "./Facebook";
export * from "./Instagram";
export * from "./Logo";
export * from "./LogoTextIcon";
export * from "./Menu";
export * from "./NextIcon";
export * from "./Phone";
export * from "./SubmitIcon";
export * from "./VK";
export * from "./YellowSquare";
export * from "./ArrowDown";
export * from "./ArrowRight";
