import React, { FC, SVGAttributes } from "react";

const NextIcon: FC<SVGAttributes<SVGSVGElement>> = props => {
  return (
    <svg
      width="32"
      height="32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="16" cy="16" r="15" stroke="#000" strokeWidth="2" />
      <path
        d="M22.707 16.707a1 1 0 0 0 0-1.414l-6.364-6.364a1 1 0 0 0-1.414 1.414L20.586 16l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364zM10 17h12v-2H10v2z"
        fill="#000"
      />
    </svg>
  );
};

export { NextIcon };
