import React, { FC } from "react";
import styled from "styled-components";
import { usePageMessages } from "../../hooks/usePageData";

const StyledAddressBlock = styled.div`
  width: 100%;
  height: 638px;
  padding: 132px;
  position: relative;

  @media (max-width: 768px) {
    height: 520px;
    padding: 54px 40px;
  }

  @media (max-width: 720px) {
    margin-top: 8px;
    height: 520px;
    padding: 32px;
    background-color: #f2f2f2;
  }

  .address-block__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .address-block__content {
    position: relative;
  }

  .address-block__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;

    color: #000000;
  }

  .address-block__description {
    max-width: 420px;
    margin-top: 16px;

    font-size: 16px;
    line-height: 24px;

    color: #5b5b5b;
  }

  .address-block__address {
    margin-top: 8px;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
`;

const AddressBlock: FC = () => {
  const {
    addressBlock: { title, description, address, imageUrl }
  } = usePageMessages();

  return (
    <StyledAddressBlock>
      <img
        className="lazyload address-block__background"
        src={imageUrl}
        alt=""
      />

      <div className="address-block__content">
        <h3 className="address-block__title">{title}</h3>
        <p className="address-block__description">{description}</p>
        <p className="address-block__address">{address}</p>
      </div>
    </StyledAddressBlock>
  );
};

export { AddressBlock };
