import React, { useCallback, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import cn from "classnames";
import { Modal } from "../../../components/modal";
import { RequestCallModal } from "../../../modules/homepage/RequestCallModal";

const StyledMenu = styled.nav`
  height: 100%;

  .menu__nav-list {
    display: flex;

    height: 100%;
  }

  .menu__nav-item {
    position: relative;

    cursor: pointer;
  }

  .menu__nav-item + .menu__nav-item {
    margin-left: 32px;
  }

  .menu__nav-link {
    display: flex;
    align-items: center;

    height: 100%;
  }

  .menu__nav-link:hover,
  .menu__nav-link--active {
    &::after {
      position: absolute;
      bottom: 0;

      width: 100%;
      height: 2px;

      content: "";

      border-radius: 2px 2px 0px 0px;
      background: black;
    }
  }

  .menu__menu-icon {
    width: 24px;
    height: 24px;
    fill: #000;
    cursor: pointer;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .menu__phone-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;

    @media (min-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 767.98px) {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);

    width: 100%;
    height: initial;

    color: #000000;
    background: #ffffff;

    &.nav-menu--mobile-open {
      display: block;
    }

    .menu__nav-list {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      padding: 0 16px 16px;
    }

    .menu__nav-item {
      padding: 16px 0;
      background: #ffffff;
    }

    .menu__nav-item + .menu__nav-item {
      margin-left: 0px;
      border-top: 1px solid #e5e5e5;
    }

    .menu__nav-link:hover,
    .menu__nav-link--active {
      &::after {
        display: none;
      }

      color: #808080;
    }
  }
`;

interface Props {
  open: boolean;
  onClose: () => void;
}

const MenuNav = ({ open, onClose }: Props) => {
  const [isOpenRequestCallModal, setIsOpenRequestCallModal] = useState(false);
  const handleOpenRequestCallModal = useCallback(
    () => setIsOpenRequestCallModal(true),
    []
  );
  const handleCloseRequestCallModal = useCallback((event: Event) => {
    event.preventDefault();
    setIsOpenRequestCallModal(false);
  }, []);

  return (
    <StyledMenu className={cn({ "nav-menu--mobile-open": open })}>
      <ul className="menu__nav-list">
        <li className="menu__nav-item">
          <Link
            className="menu__nav-link"
            activeClassName="menu__nav-link--active"
            to="/repair"
          >
            Ремонт техники
          </Link>
        </li>
        <li className="menu__nav-item">
          <Link
            className="menu__nav-link"
            activeClassName="menu__nav-link--active"
            to="/trade-in"
          >
            Trade in
          </Link>
        </li>
        <li className="menu__nav-item" onClick={onClose}>
          <a href="#services" className="menu__nav-link">
            Наши услуги
          </a>
        </li>
        <li className="menu__nav-item" onClick={onClose}>
          <span className="menu__nav-link" onClick={handleOpenRequestCallModal}>
            Заказать звонок
          </span>
        </li>
        <li className="menu__nav-item" onClick={onClose}>
          <a className="menu__nav-link" href="#contacts">
            Контакты
          </a>
        </li>
      </ul>
      <Modal
        isOpen={isOpenRequestCallModal}
        onClose={handleCloseRequestCallModal}
      >
        <RequestCallModal />
      </Modal>
    </StyledMenu>
  );
};

export { MenuNav };
