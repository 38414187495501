import React, { FC, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { CloseModal } from "../icons";

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: (event: Event) => void;
}

const StyledModal = styled.div`
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  overflow-y: auto;

  background-color: rgba(0, 0, 0, 0.64);

  .modal__wrap {
    flex: 1 1 0%;
    margin: 64px auto;
  }

  .modal__inner-wrap {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .modal {
    position: relative;
  }

  .modal__content {
    border-radius: 24px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  }

  .modal__close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    fill: #cbcbcb;

    cursor: pointer;

    &:hover {
      fill: #333333;
    }
  }
`;

const Modal: FC<Props> = ({ children, isOpen, onClose }): any => {
  if (typeof window === "undefined") {
    return null;
  }

  const portalRoot: any = document.getElementById("portal");
  const element: any = document.createElement("div");
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      portalRoot.appendChild(element);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen, portalRoot]);

  useOutsideClick(modalRef, onClose);

  return (
    isOpen &&
    ReactDOM.createPortal(
      <StyledModal>
        <div className="modal__wrap">
          <div className="modal__inner-wrap">
            <div className="modal" ref={modalRef}>
              <CloseModal
                className="modal__close-icon"
                aria-label="Close"
                onClick={onClose}
              />
              <div className="modal__content">{children}</div>
            </div>
          </div>
        </div>
      </StyledModal>,
      element
    )
  );
};

export { Modal };
