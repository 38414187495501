import { useStaticQuery, graphql } from "gatsby";

const useWarrantyRepairData = () => {
  const { prismicWarrantyrepair } = useStaticQuery(graphql`
    query {
      prismicWarrantyrepair {
        data {
          body {
            items {
              category_list {
                text
              }
            }
            primary {
              category_icon {
                url
              }
              category_title {
                text
              }
            }
          }
          title {
            text
          }
          subtitle {
            text
          }
          helpertext {
            text
          }
        }
      }
    }
  `);
  return prismicWarrantyrepair.data;
};

const useWarrantyRepairMessages = () => {
  const warrantyRepairData = useWarrantyRepairData();

  return {
    title: warrantyRepairData.title.text,
    subtitle: warrantyRepairData.subtitle.text,
    helperText: warrantyRepairData.helpertext.text,
    categories: warrantyRepairData.body.map((category: any) => ({
      categoryTitle: category.primary.category_title.text,
      categoryIconUrl: category.primary.category_icon.url,
      categoryList: category.items.map((elem: any) => elem.category_list.text)
    }))
  };
};

export { useWarrantyRepairMessages };
