import React, { FC, SVGAttributes } from "react";

const Menu: FC<SVGAttributes<SVGSVGElement>> = props => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fill="#000" d="M2 7h20v2H2zM2 16h20v2H2z" />
    </svg>
  );
};

export { Menu };
