import React, { FC, SVGAttributes } from "react";

const CloseModal: FC<SVGAttributes<SVGSVGElement>> = props => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.9047 1.42856C6.1289 1.42856 1.42847 6.129 1.42847 11.9047C1.42847 17.6805 6.1289 22.3809 11.9047 22.3809C17.6804 22.3809 22.3808 17.6805 22.3808 11.9047C22.3808 6.129 17.6804 1.42856 11.9047 1.42856ZM16.0527 15.3757C16.2387 15.5617 16.2387 15.8668 16.0527 16.0528C15.9578 16.1421 15.8388 16.1904 15.7143 16.1904C15.5896 16.1904 15.4705 16.1421 15.3757 16.0528L11.9047 12.5818L8.43365 16.0528C8.33878 16.1421 8.21972 16.1904 8.09522 16.1904C7.97053 16.1904 7.85147 16.1421 7.75659 16.0528C7.57059 15.8668 7.57059 15.5617 7.75659 15.3757L11.2276 11.9047L7.75659 8.43375C7.57059 8.24775 7.57059 7.94268 7.75659 7.75668C7.94259 7.57068 8.24765 7.57068 8.43365 7.75668L11.9047 11.2277L15.3757 7.75668C15.5617 7.57068 15.8667 7.57068 16.0527 7.75668C16.2387 7.94268 16.2387 8.24775 16.0527 8.43375L12.5817 11.9047L16.0527 15.3757Z" />
    </svg>
  );
};

export { CloseModal };
