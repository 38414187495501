import React, { FC, SVGAttributes } from "react";

const Arrow: FC<SVGAttributes<SVGSVGElement>> = props => {
  return (
    <svg
      width="6"
      height="10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 1l4 4-4 4" stroke="#0057FF" />
    </svg>
  );
};

export { Arrow };
