import React, { FC } from "react";
import styled from "styled-components";
import { ListItem } from "./listitem";

interface Props {
  icon?: string;
  categoryTitle: string;
  list: string[];
}

const StyledCategory = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 62px;
  border-bottom: 1px solid #cbcbcb;
  width: 280px;

  &:not(:first-child) {
    padding-left: 40px;
  }

  @media (max-width: 599px) {
    border-bottom: none;
  }

  @media (max-width: 769px) {
    &:last-child {
      border-bottom: none;
    }
  }

  @media (max-width: 599px) {
    padding-bottom: 30px;
  }

  @media (min-width: 600px) and (max-width: 769px) {
    width: 100%;
    padding-left: 0px;
    padding-bottom: 30px;

    &:not(:first-child) {
      margin-top: 30px;
      padding-left: 0px;
    }
  }

  .category-header {
    display: flex;
    align-items: center;
    height: 32px;

    @media (max-width: 599px) {
      align-items: flex-end;
    }
  }

  .category-header__icon {
    margin-right: 20px;
    max-width: 32px;
    max-height: 32px;
  }
  .category-header__title {
    order: 1;
    font-size: 20px;
    line-height: 27px;
  }

  .category__list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 25px;
    height: 85px;

    @media (max-width: 599px), (min-width: 769px) {
      height: unset;
    }
  }
`;

const Category: FC<Props> = ({ icon, categoryTitle, list }) => {
  return (
    <StyledCategory>
      <div className="category-header">
        {icon && (
          <img
            className="lazyload category-header__icon"
            data-src={`${icon}`}
            alt=""
          />
        )}
        <h6 className="category-header__title">{categoryTitle}</h6>
      </div>
      <ul className="category__list">
        {list.map(item => (
          <ListItem key={item}>{item}</ListItem>
        ))}
      </ul>
    </StyledCategory>
  );
};

export { Category };
