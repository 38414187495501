import React, { FC, SVGAttributes } from "react";

const Phone: FC<SVGAttributes<SVGSVGElement>> = props => {
  return (
    <svg
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.5 14c0 7.456-6.044 13.5-13.5 13.5S.5 21.456.5 14 6.544.5 14 .5 27.5 6.544 27.5 14z"
        stroke="#000"
      />
      <path
        d="M5.895 12.206l.01-.01c1.747-1.636 5.366-1.978 8.097-1.977 2.732 0 6.353.342 8.089 1.972.61.571.21 3.07.206 3.096-.114.638-.625.963-1.033.897-.277-.045-3.04-.611-3.349-.68-.32-.073-.593-.383-.73-.83a66.095 66.095 0 0 1-.392-1.393c-.347-.06-1.226-.182-2.79-.182-1.565 0-2.444.12-2.792.182-.1.365-.314 1.145-.391 1.393-.136.441-.406.75-.725.827-.326.078-3.09.634-3.35.68a.816.816 0 0 1-.652-.197 1.178 1.178 0 0 1-.385-.7c-.005-.023-.398-2.493.187-3.078z"
        fill="#000"
      />
    </svg>
  );
};

export { Phone };
