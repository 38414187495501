import cn from "classnames";
import React from "react";
import styled from "styled-components";
import { usePageMessages } from "../../../hooks/usePageData";
import { Content } from "../../content";

const StyledContactsHeader = styled.div`
  color: #ffffff;
  background: #111111;

  font-size: 12px;

  .contacts-header__header {
    display: flex;
    justify-content: space-between;

    padding: 12px 0;
  }

  .contacts-header__contacts {
    display: flex;

    height: 100%;
  }

  .contacts-header__phones {
    display: flex;

    margin-left: 32px;
  }

  .contacts-header__phone {
    font-weight: bold;
  }

  .contacts-header__phone + .contacts-header__phone {
    margin-left: 32px;
  }

  .contacts-header__socials {
    display: none;
  }

  @media (max-width: 767.98px) {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);

    width: 100%;
    height: initial;
    padding: 32px 0 40px;

    color: #000000;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

    &.contacts-header--mobile-open {
      display: block;
    }

    .contacts-header__header {
      flex-direction: column;
      padding: 0;
    }

    .contacts-header__address {
      display: none;
    }

    .contacts-header__contacts {
      flex-direction: column-reverse;
      align-items: center;
    }

    .contacts-header__contact {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

    .contacts-header__phones {
      flex-direction: column;

      margin-left: 0;
    }

    .contacts-header__phone + .contacts-header__phone {
      margin-top: 12px;
      margin-left: 0;
    }

    .contacts-header__email {
      margin-top: 20px;
    }

    .contacts-header__socials {
      margin-top: 24px;
      display: flex;
    }

    .contacts-header__social + .contacts-header__social {
      margin-left: 28px;
    }
  }
`;
interface Props {
  open?: boolean;
}

const ContactsHeader = ({ open }: Props) => {
  const {
    header: { address, email, phoneNumbers, socials }
  } = usePageMessages();

  return (
    <StyledContactsHeader
      className={cn({ "contacts-header--mobile-open": open })}
    >
      <Content>
        <div className="contacts-header__header">
          <p className="contacts-header__address">{address}</p>
          <div className="contacts-header__contacts">
            <ul className="contacts-header__socials">
              {socials.map(
                (
                  social: {
                    link: string;
                    icon: { url: string; alt: string };
                  },
                  index: number
                ) => (
                  <li className="contacts-header__social" key={index}>
                    <a href={social.link} target="_blank">
                      <img
                        className="lazyload"
                        data-src={social.icon.url}
                        alt={social.icon.alt}
                      />
                    </a>
                  </li>
                )
              )}
            </ul>
            <p className="contacts-header__contact contacts-header__email">
              {email}
            </p>
            <ul className="contacts-header__phones">
              {phoneNumbers.map((phone: string) => (
                <a
                  href={`tel:${phone}`}
                  key={phone}
                  className="contacts-header__contact contacts-header__phone"
                >
                  {phone}
                </a>
              ))}
            </ul>
          </div>
        </div>
      </Content>
    </StyledContactsHeader>
  );
};
export { ContactsHeader };
