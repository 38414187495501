import React, { FC, ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode;
}

const StyledListItem = styled.li`
  position: relative;
  font-size: 16px;
  padding-left: 12px;
  line-height: 28px;
  color: #5b5b5b;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    width: 4px;
    height: 4px;
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: #5b5b5b;
  }
`;

const ListItem: FC<Props> = ({ children }) => {
  return <StyledListItem>{children}</StyledListItem>;
};

export { ListItem };
